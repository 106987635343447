<template>
  <div>
    <v-dialog
      v-model="dialog"
      scrollable
      width="60vh"
    >
      <v-card>

        <v-card-title>
          Cancellation Reason
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-form ref="cancelReasonForm">
              <v-row>
                <v-col
                  cols='12'
                  xs='12'
                  md='12'
                >
                  <v-textarea
                    placeholder="Cancellation Reason"
                    label="Cancellation Reason"
                    v-model="cancelReason"
                    :rules="[v=>!!v || 'Please enter a cancellation reason']"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class='primary'
            @click="formSave"
          >Save</v-btn>
          <v-btn
            text
            @click="formCancel"
          >Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {

  methods:
  {
    askCancelReason (ticket)
    {
      console.log("askCancelReason : ticket : ", ticket);
      this.ticket = ticket;
      this.dialog = true;
    },

    formSave ()
    {
      if (!this.$refs.cancelReasonForm.validate())
      {
        return false;
      }
      this.$emit("formSaved", this.ticket, this.cancelReason);
      this.dialog = false;
    },

    formCancel ()
    {
      this.$emit("formCancelled");
      this.dialog = false;
    }

  },

  data: () =>
  {

    return {
      cancelReason: '',
      dialog: false,
      ticket: null,
    }
  }
}
</script>