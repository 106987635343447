<template>
  <v-dialog v-model="dialog" max-width="1200" scrollable persistent>
    <v-card>
      <v-card-title class="primary white--text">
        <v-toolbar-title>Ticket Info</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="onClose">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-card class="my-1 py-3">
          <v-form ref="formdetails" class="mx-2" lazy-validation>
            <table class="dialog-content-table">
              <tbody>
                <tr>
                  <td class="text-left">Company Name:</td>
                  <td>
                    <v-combobox v-model="ticket.CompanyName" disabled />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Driver:</td>
                  <td>
                    <v-combobox v-model="ticket.DriverName" disabled />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Vehicle registration:</td>
                  <td>
                    <v-combobox v-model="ticket.VehicleReg" required disabled />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">Trailer Number:</td>
                  <td>
                    <v-combobox v-model="ticket.TrailerNumber" disabled />
                  </td>
                </tr>
                <tr>
                  <td class="text-left">QC Document Reference:</td>
                  <td>
                    <v-text-field
                      v-model="ticket.DocumentRef"
                      disabled
                    ></v-text-field>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-form>
        </v-card>
        <v-card class="mb-1 mt-4 py-3">
          <v-card-text>
            <v-form ref="forminbound" class="mx-2" lazy-validation>
              <v-row>
                <v-col cols="12" xs="12" md="12">
                  <span class="text-subtitle-1">Weight 1</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" md="2">
                  <v-select
                    :solo="isNew"
                    label="Type"
                    disabled
                    :items="weighingType"
                    v-model="ticket.InboundType"
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-select
                    :solo="isNew"
                    label="Weighbridge"
                    disabled
                    :items="weighbridges"
                    item-text="name"
                    item-value="code"
                    v-model="ticket.InboundInstrument"
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <v-text-field
                    :solo="isNew"
                    disabled
                    :readonly="username !== 'SELECT'"
                    type="number"
                    suffix="kgs"
                    v-model="ticket.InboundWeightKG"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" md="11">
                  <v-text-field
                    placeholder="Tare Weight (KG)"
                    label="Tare Weight (KG)"
                    type="number"
                    v-model="ticket.TareWeightKG"
                    outlined
                    persistent-hint
                    outline
                    clearable
                    disabled
                  />
                  <v-text-field
                    :solo="isNew"
                    disabled
                    type="text"
                    label="Comments"
                    v-model="ticket.InboundComment"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" md="12"> </v-col>
              </v-row>
            </v-form>
            <v-form ref="formoutbound" class="mx-2" lazy-validation>
              <v-row v-if="!selectedTicket.TareWeightKG">
                <v-col cols="12" xs="12" md="12">
                  <span class="text-subtitle-1">Weight 2</span>
                </v-col>
              </v-row>
              <v-row v-if="!selectedTicket.TareWeightKG">
                <v-col cols="12" xs="12" md="2">
                  <v-select
                    label="Type"
                    disabled
                    :items="weighingType"
                    v-model="ticket.OutboundType"
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="12" md="4">
                  <v-select
                    label="Weighbridge"
                    disabled
                    :items="weighbridges"
                    item-text="name"
                    item-value="code"
                    v-model="ticket.OutboundInstrument"
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <v-text-field
                    disabled
                    :readonly="username !== 'SELECT'"
                    type="number"
                    suffix="kgs"
                    v-model="ticket.OutboundWeightKG"
                  />
                </v-col>
              </v-row>
              <v-row v-if="!selectedTicket.TareWeightKG">
                <v-col cols="12" xs="12" md="11">
                  <v-text-field
                    disabled
                    hide-details
                    type="text"
                    label="Comments"
                    v-model="ticket.OutboundComment"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="onPressPrintTicket"
              :disabled="isNew || selectedTicket.Cancelled"
              icon
              outlined
              class="mr-4"
            >
              <v-icon>mdi-printer</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import SelectFunctions from "../select/SelectFunctions";

export default {
  name: "WeighingDialogQueriesSearch",

  data() {
    return {
      ticket: {},
      weighingType: ["LADEN", "UNLADEN"],
      weighbridges: [],
      username: "",
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    selectedTicket: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isNew() {
      return this.ticket.Status !== "OPEN" && this.ticket.Status !== "CLOSED";
    },
    isClosed() {
      return this.ticket.Status === "CLOSED";
    },
  },

  async mounted() {
    this.weighbridges = JSON.parse(process.env.VUE_APP_WEIGHBRIDGE_INSTRUMENTS);
    // lookup pre-selects based on previous weighbridge tickets
    this.username = await SelectFunctions.getUsername();
    this.username = this.username.toUpperCase();
  },

  methods: {
    onClose() {
      this.$emit("onClose");
    },

    onPressPrintTicket() {
      var sURL =
        process.env.VUE_APP_BACKEND_ENDPOINT +
        "tickets/" +
        this.ticket.id +
        "/print";
      window.open(sURL);
    },
  },
  watch: {
    dialog: async function (newvalue) {
      if (newvalue) {
        this.ticket = Object.assign(this.selectedTicket);
      }
    },
  },
};
</script>