<template>
  <div>
    <v-row>
      <v-col cols="12" xs="12" md="12">
        <v-card dense>
          <v-card-title class="primary white--text" dense>
            Ticket Search Results </v-card-title
          ><br />
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="ticketList"
              :search="searchTicket"
              :xxcustom-filter="onSearch"
              xxclass="elevation-1 pt-4"
              @click:row="onClickLine"
              :single-expand="singleExpand"
              :expanded.sync="expanded"
              show-expand
            >
              <template v-slot:top>
                <v-row align="center">
                  <v-col cols="8" sm="6">
                    <v-text-field
                      v-model="searchTicket"
                      label="Search"
                      class="mx-4"
                      prepend-icon="mdi-magnify"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="8" sm="6">
                    <v-select
                      :items="ticketList"
                      item-value="Status"
                      item-text="Status"
                      v-model="filterTicketValue"
                      label="Filter"
                      multiple
                    ></v-select>
                  </v-col>
                </v-row>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <br />
                  <v-row no-gutters>
                    <v-col md="2">
                      <b style="color: grey">{{ item.InboundInstrument }}:</b>
                    </v-col>
                    <v-col md="2">
                      {{ item.InboundWeightKG.toString().concat(" KG") }}<br />
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col md="2">
                      <b style="color: grey">Time created:</b>
                    </v-col>
                    <v-col md="2">
                      <template>
                        {{ dayjs(item.created_at).format("DD/MM/YYYY HH:mm") }}
                      </template>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <template>
                      <v-col md="2">
                        <span v-if="!item.OutboundWeightKG"></span>
                        <span v-else>
                          <b style="color: grey"
                            >{{ item.OutboundInstrument }}:</b
                          >
                        </span>
                      </v-col>
                      <v-col md="2">
                        <span v-if="!item.OutboundWeightKG"></span>
                        <span v-else>
                          {{ item.OutboundWeightKG.toString().concat(" KG") }}
                        </span>
                      </v-col>
                    </template>
                  </v-row>
                  <v-row no-gutters>
                    <template>
                      <v-col md="2">
                        <span v-if="!item.TareWeightKG"></span>
                        <span v-else>
                          <b style="color: grey">TareWeight:</b>
                        </span>
                      </v-col>
                      <v-col md="2">
                        <span v-if="!item.TareWeightKG"></span>
                        <span v-else>
                          {{ item.TareWeightKG.toString().concat(" KG") }}
                        </span>
                      </v-col>
                    </template>
                  </v-row>

                  <v-row
                    no-gutters
                    v-if="item.FuelTankWeightKG && item.FuelTankWeightKG > 1"
                  >
                    <v-col md="2">
                      <b style="color: grey">Used Fuel Adjustment:</b>
                    </v-col>
                    <v-col md="2">{{ item.FuelTankWeightKG }} KG</v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col md="2">
                      <b style="color: grey">Net Weight:</b>
                    </v-col>
                    <v-col md="2">{{ onNet(item) }}</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col md="2">
                      <b style="color: grey">Last Update: </b>
                    </v-col>
                    <v-col md="2"
                      ><template>{{
                        dayjs(item.updated_at).format("DD/MM/YYYY HH:mm")
                      }}</template>
                    </v-col> </v-row
                  ><br />
                </td>
              </template>
              <template v-slot:item.TicketNumber="{ value }">
                {{ value.toString().padStart(8, "0") }}
              </template>
              <template v-slot:item.Status="{ item }">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                  "
                  :class="{
                    'closed-status': item.Status === 'CLOSED',
                    'open-status': item.Status === 'OPEN',
                    'cancelled-status': item.Status === 'CANCELLED',
                  }"
                >
                  {{ item.Status }}
                </div>
              </template>
              <template v-slot:item.printAction="{ item }">
                <template xxv-if="item.Status === 'CLOSED'">
                  <v-btn
                    icon
                    outlined
                    tile
                    :color="colors.closed"
                    @click.stop="onPrint(item)"
                    v-if="!item.Cancelled"
                  >
                    <v-icon :color="colors.closed">mdi-receipt</v-icon>
                  </v-btn>
                </template>
              </template>
              <template v-slot:item.printActionA4="{ item }">
                <v-btn
                  icon
                  outlined
                  tile
                  :color="colors.closed"
                  @click.stop="onPrintA4(item)"
                  v-if="!item.Cancelled"
                >
                  <v-icon :color="colors.closed">mdi-printer</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.created_at="{ value }">
                {{ dayjs(value).format("DD/MM/YYYY HH:mm") }}
              </template>
              <template v-slot:item.InboundWeightKG="{ item }">
                {{ onNet(item) }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <cancel-reason ref="cancelReasonForm" @formSaved="processCancellation" />
  </div>
</template>

<script>
import dayjs from "dayjs";
import CancelReason from "@/components/CancelReason";

export default {
  name: "TicketList",
  components: {
    CancelReason,
  },

  data() {
    return {
      colors: {
        closed: "grey",
        open: "red",
      },
      expanded: [],
      singleExpand: false,
      headers: [
        {
          text: "Ticket N°",
          align: "start",
          sortable: true,
          value: "TicketNumber",
          xxclass: "primary white--text",
        },
        {
          text: "Company Name",
          sortable: true,
          value: "CompanyName",
          xxclass: "primary white--text",
        },
        {
          text: "Driver Name",
          sortable: true,
          value: "DriverName",
          xxclass: "primary white--text",
        },
        {
          text: "Vehicle Registration",
          sortable: true,
          value: "VehicleReg",
          xxclass: "primary white--text",
        },
        {
          text: "Trailer Number",
          sortable: true,
          value: "TrailerNumber",
          xxclass: "primary white--text",
        },
        {
          text: "QC Number",
          sortable: true,
          value: "DocumentRef",
          xxclass: "primary white--text",
        },
        {
          text: "Created Date",
          sortable: true,
          value: "created_at",
          xxclass: "primary white--text",
        },
        {
          text: "Status",
          sortable: true,
          value: "Status",
          xxclass: "primary white--text",
          filter: this.onFilter,
        },
        {
          text: "Net Weight",
          sortable: true,
          value: "InboundWeightKG",
          xxclass: "primary white--text",
        },
        {
          text: "",
          sortable: true,
          value: "printAction",
          xxclass: "primary white--text",
        },
        {
          text: "",
          sortable: true,
          value: "printActionA4",
          xxclass: "primary white--text",
        },
        {
          text: "",
          sortable: true,
          value: "deleteAction",
          xxclass: "primary white--text",
        },
      ],

      searchTicket: "",
      filterTicketValue: [],
    };
  },
  props: {
    ticketList: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    /**
     * When the row is clicked open up the popup /smg/
     */
    onClickLine(oTicket) {
      this.$emit("openWeighingDialog", oTicket);
    },

    onSearch(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },

    onNet(item) {
      let value;

      if (item.InboundWeightKG && item.OutboundWeightKG && !item.TareWeightKG) {
        value = Math.abs(Number(item.InboundWeightKG - item.OutboundWeightKG))
          .toString()
          .concat(" KG");
        return value;
      }
      if (item.InboundWeightKG && item.TareWeightKG && !item.OutboundWeightKG) {
        value = Math.abs(
          Number(item.InboundWeightKG) -
            (Number(item.TareWeightKG) -
              Number(
                item.FuelTankWeightKG && item.FuelTankWeightKG > 1
                  ? item.FuelTankWeightKG
                  : 0.0
              ))
        )
          .toString()
          .concat(" KG");
        return value;
      }
      if (!item.TareWeightKG && !item.OutboundWeightKG) {
        console.log("Net cannot be calculated, no second weight");
        return;
      }
    },

    onFilter(value) {
      if (this.filterTicketValue.length == 0) {
        return value;
      }

      if (this.filterTicketValue.indexOf(value) > -1) {
        console.log("if");
        return (value = true);
      } else {
        console.log("else");
        return value == this.filterTicketValue.status;
      }
    },

    onPrint(oTicket) {
      var sURL =
        process.env.VUE_APP_BACKEND_ENDPOINT +
        "tickets/" +
        oTicket.id +
        "/print";
      window.open(sURL);
    },

    onPrintA4(oTicket) {
      var sURL =
        process.env.VUE_APP_BACKEND_ENDPOINT +
        "tickets/" +
        oTicket.id +
        "/print/a4";
      window.open(sURL);
    },

    async handleCancelActionClick(item) {
      this.$refs.cancelReasonForm.askCancelReason(item);
    },

    async processCancellation(ticket, cancellationReason) {
      console.log("processCancellation : ticket : ", ticket);

      try {
        let response = await fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + `tickets/${ticket._id}/cancel`,
          {
            credentials: "include",
            method: "PATCH",
            body: JSON.stringify({
              cancellationReason: cancellationReason,
            }),
          }
        );

        if (!(response && response.ok)) {
          throw "Invalid return from server : " + response.status;
        }

        this.$emit("reloadTicketList");
      } catch (e) {
        console.log("processCAncellation : caught error : ", e);
        this.$notify({
          group: "global",
          type: "error",
          title: "Problem Cancelling",
          text: "There was a problem cancelling the ticket, please re-try",
        });
      }
    },
  },

  computed: {
    dayjs: () => dayjs,
  },
};
</script>
