<template>
  <div>
    <v-container id="content-container" class="mt-6" fluid>
      <v-row>
        <v-col cols="12" xs="12">
          <span class="text-h3">Search Ticket Queries</span>
        </v-col>
      </v-row>
<v-form ref="ticketForm">
      <v-row>
        <v-col cols="12" xs="12" md="12">
          <v-text-field
            placeholder="Date From"
            label="From"
            type="date"
            clearable
            v-model="ticketDetails.dateFrom"
          />
          <v-text-field
            placeholder="Date to"
            label="Date to"
            type="date"
            clearable
            :rules="[
              (v) => {
                if ((ticketDetails.dateFrom && v >= ticketDetails.dateFrom) || !v) return true;
                return 'Please enter the appropriate end date';
              },
            ]"
            v-model="ticketDetails.dateTo"
          />
          <v-autocomplete
            menu-props="auto"
            label="Customer Number"
            placeholder="Customer Number"
            :items="ticketSearch"
            item-text="CompanyName"
            v-model="ticketDetails.CompanyName"
          />
          <v-autocomplete
            menu-props="auto"
            label="Driver Number"
            placeholder="Driver Number"
            :items="ticketSearch"
            item-text="DriverName"
            v-model="ticketDetails.DriverName"
          />
          <v-autocomplete
            menu-props="auto"
            label="Ticket Number"
            placeholder="Ticket Number"
            :items="ticketSearch"
            item-text="TicketNumber"
            v-model="ticketDetails.TicketNumber"
          />
          <v-btn
            xxoutlined
            color="success"
            @click="searchTickets"
            large
            class="mr-2"
            >Search</v-btn
          >
        </v-col>
      </v-row>
</v-form>
      <v-row class="mb-8">
        <v-col cols="12" xs="12" md="12"> </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" xs="12" md="12">
          <ticket-list-queries-search
            @openWeighingDialog="openWeighingDialog"
            :ticketList="ticketList"
          >
          </ticket-list-queries-search>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" xs="12" md="12">
          <span class="text-caption">App Version :{{ appVersion }}</span>
        </v-col>
      </v-row>
    </v-container>

    <weighing-dialog-queries-search
      :dialog="weighingDialog"
      :selectedTicket="selectedTicket"
      @onClose="closeWeightinDialog"
    >
    </weighing-dialog-queries-search>
  </div>
</template>

<script>
import dayjs from "dayjs";
import SelectFunctions from "@/select/SelectFunctions";
import TicketListQueriesSearch from "../components/TicketListQueriesSearch.vue";
import WeighingDialogQueriesSearch from "../components/WeighingDialogQueriesSearch.vue";

import { version } from "../../package";

export default {
  components: {
    TicketListQueriesSearch,
    WeighingDialogQueriesSearch,
  },

  data() {
    return {
      processingDate: null,
      ticketList: [],
      ticketDetails: {},
      ticketSearch: [],

      selectedTicket: {},
      weighingDialog: false,
      appVersion: version,
    };
  },

  computed: {},

  mounted() {
    this.processingDate = dayjs(dayjs().format("YYYY-MM-DD")).toISOString();
    this.loadTicketDetails();

  },

  methods: {

    openWeighingDialog(oTicket) {
      this.weighingDialog = true;
      this.selectedTicket = oTicket;
      console.log(oTicket);
    },
    closeWeightinDialog() {
      this.weighingDialog = false;
    },

    async searchTickets() {

      if (!this.$refs.ticketForm.validate()) {
        return false;
      }
        console.log(this.ticketDetails);

      try {
        let response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + "ticketsSearch/filteredTickets",
          { 
            credentials: "include",
            method: "POST",
            body: JSON.stringify({
              ticketDetails: this.ticketDetails,
            }),
          
          }
        );

        if (!(response && response.ok)) {
          throw "Invalid response from server : " + response.status;
        }

        let result = await response.json();
        this.ticketList = result;
        console.log(result);
      } catch (e) {
        console.log("loadTickets : caught error : ", e);
      }
    },

     async loadTicketDetails() {

      try {
        let response = await SelectFunctions.fetch(
          process.env.VUE_APP_BACKEND_ENDPOINT + "ticketsSearch/ticketDetails",
          { 
            credentials: "include"       
          }
        );

        if (!(response && response.ok)) {
          throw "Invalid response from server : " + response.status;
        }

        let result = await response.json();
        this.ticketSearch = result;
      } catch (e) {
        console.log("loadTicketDetails : caught error : ", e);
      }
    },


  },
};
</script>

<style scoped>
</style>
